
import Provider from '@/services/provider'
import gtm from '~/mixins/gtm.js'
import botChecker from '~/mixins/botChecker.js'
import homeEmbed from '~/components/Templates/Home/HomeEmbed.vue'
import homeNew from '~/components/Templates/Home/HomeNew.vue'
import homeOrganic from '~/components/Templates/Home/HomeOrganic.vue'

export default {
  mixins: [gtm, botChecker],

  components: {
    homeEmbed,
    homeNew,
    homeOrganic,
  },

  data() {
    return {
      // GTM
      gtmSubsection: 'Home',
      gtmSubsection2: '',
      // SEO
      title: this.$t('seo.home.title'),
      description: this.$t('seo.home.description'),
      url: `${process.env.BASE_URL}${this.$route.path}`,
      locale: this.$i18n.locales.find(l => l.code === this.$i18n.locale).iso,
      routeName: this.$route.name,
      // DATA
      video: this.$t('pages.home.hero.video'),
      faqSchema: this.$t('pages.home.faq.items'),
      orgSchema: { description: this.$t('seo.home.description'), ...this.$t('seo.organization') },
    }
  },

  head() {
    const basePayload = {
      title: this.title,
      description: this.description,
      url: this.url,
      locale: this.locale,
      routeName: this.routeName,
    }

    return {
      ...Provider.head.useComposeHeadTag({
        ...basePayload,
        schema: [
          { ...Provider.head.setSchemaOrganization({ ...this.orgSchema }) },
          { ...Provider.head.setSchemaWebpage({ ...basePayload }) },
          { ...Provider.head.setSchemaFaq({ items: this.faqSchema }) },
          { ...Provider.head.setSchemaVideo({ video: this.video }) },
        ],
        meta: {},
        link: [
          {
            rel: 'preconnect',
            href: 'https://sso.hotmart.com/signup',
          },
        ],
      }),
    }
  },

  computed: {
    breadcrumbs() {
      const payload = {
        routePath: this.$route.path,
        dinamicValue: this.$route.name && this.$route.name.includes('tour-id') ? 'Video' : false,
      }

      const list = Provider.breadcrumbs.getItemList(payload)

      return list
    },

    isOrganicWithoutTest() {
      return ['pt-br', 'es', 'es-co', 'es-mx', 'es-es'].includes(this.$i18n.locale)
    },
  },
}
